import getSetting from './getSetting'
import getUserInfo from './getUserInfo'
import getXAxisData from './getXAxisData'
import getTheme from './getTheme'
import hasPower from './hasPower'
import Vue from 'vue'
import {VERSION} from '../constants/common';
import {getIsApp} from "@/common/utility/utils";
export default {
  install(Vue, options) {
    /** 配置 */
    Vue.prototype.getSetting = getSetting;
    /** 用户信息 */
    Vue.prototype.getUserInfo = getUserInfo;
    /** 权限 */
    Vue.prototype.hasPower = hasPower;
    /** 获取主题 */
    Vue.prototype.getTheme = getTheme;
    /** 图标获取X轴月度/季度 */
    Vue.prototype.getXAxisData = getXAxisData;

    /** 本地 日期 对象生成方法 date 不存在则 返回 空 */
    Vue.prototype.NewDate = (date) => {
      let val = date
      let dateObject = new Date();
      let isDate = date instanceof Date
      if (!isDate && val) {
        if (val.includes('-')) {
          val = Vue.prototype.beTime(val, 'yyyy/MM/dd 00:00:00');
        }
        dateObject = new Date(val);
      } else if (isDate) {
        dateObject = new Date(val);
      }
      return dateObject;
    }
    /**
     * 毫秒数转时间
     * @param ms 毫秒
     */
    Vue.prototype.beTime = function(ms, format = 'yyyy-MM-dd') {
      let isDate = ms instanceof Date
      if (!isDate && `${ms}`.includes('-') && !`${ms}`.startsWith('-')) {
        let year = '1900'
        let month = '01'
        let day = '01'
        let hours = '00'
        let minutes = '00'
        let seconds = '00'
        let res = ''
        if (ms.includes(' ')) {
          let date = ms.split(' ')[0]
          year = date.split('-')[0]
          month = date.split('-')[1]
          day = date.split('-')[2]
          month = month.length === 1 ? '0' + month : month;
          day = day.length === 1 ? '0' + day : day;
          let time = ms.split(' ')[1]
          let timelist = time.split(':')
          timelist.length > 0 && (hours = timelist[0]);
          timelist.length > 1 && (minutes = timelist[1]);
          timelist.length > 2 && (seconds = timelist[1]);
          res = format.replace('yyyy', year).replace('MM', month).replace('dd', day).replace('hh', hours).replace('mm', minutes).replace('ss', seconds)
        } else {
          year = ms.split('-')[0]
          month = ms.split('-')[1]
          day = ms.split('-')[2]
          month = month.length === 1 ? '0' + month : month;
          day = day.length === 1 ? '0' + day : day;
          res = format.replace('yyyy', year).replace('MM', month).replace('dd', day)
        }
        return res
      } else {
        let date = ''
        if (isDate) {
          date = new Date(ms)
        } else {
          let val = ms.toString().length <= 10 ? ms * 1000 : ms
          date = new Date(val)
        }
        /** 将获取的日期格式转成北京时间 */
        // let currentDate = new Date()
        // let tmpMins = date.getMinutes()
        // let timeZone = currentDate.getTimezoneOffset() + 480
        // date = new Date(date.setMinutes(tmpMins + timeZone))
        /**********************************************************************************************/
        var fmt = format // yyyy-MM-dd hh:mm
        if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        let o = {
          'M+': date.getMonth() + 1,
          'd+': date.getDate(),
          'h+': date.getHours(),
          'm+': date.getMinutes(),
          's+': date.getSeconds()
        }
        for (let k in o) {
          if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
          }
        }
        return fmt
      }
    }
    /**
     * 日期格式转十位时间戳
     */
    Vue.prototype.beTimeNumber = function(date) {
      if (date.length !== 10 || !date.includes('-')) {
        console.log('!!!!!!!!!beTimeNumber入参不符合要求!!!!!!!!')
        let list = window.localStorage.BUG_PATH ? JSON.parse(window.localStorage.BUG_PATH) : []
        let item = location.href.split('#')
        let itemAfter = item[1] ? item[1] : item
        let index = list.indexOf(itemAfter)
        if (index >= 0) {
          list.splice(index, 1, itemAfter)
        } else {
          list.push(itemAfter)
        }
        window.localStorage.BUG_PATH = JSON.stringify(list)
      }
      // if (Number(date).toString().length === 10) {
      //   return date
      // } else if (Number(date).toString().length === 13) {
      //   return Number(date) / 1000
      // } else {
      //   return Math.round(new Date(date).getTime() / 1000).toString()
      // }
      return date
    }

    /** 日期转时间戳 */
    Vue.prototype.beTimeDateNumber = function(date) {
      if (!date) {
        return 0
      }
      date = date.replace(' ', '-')
      date = date.replace(/[:]/g, '-')
      let list = date.split('-')
      return new Date(list[0], list[1] - 1 , list[2], list[3] || 0, list[4] || 0, list[5] || 0).getTime()
    }
    /**
     * 日期字符串得出今天/明天/周N
     * @param {*日期 形如: 2018-02-10} date
     */
    Vue.prototype.getWeeks = function(date) {
      if (!date) { return }
      let list = date.split('-')
      let week = new Date(list[0], list[1] - 1, list[2]).getDay()
      // let language = window.localStorage.language
      let res = ''
      let $t = Vue.prototype.$t
      // if (language === 'CN' || language === 'TC' || language === 'JAP' || language === 'HK') {
      //   // if (date === Vue.prototype.beTime(new Date())) {
      //   //   res = '今天'
      //   // } else if (date === Vue.prototype.beTime(new Date().setDate(new Date().getDate() + 1))) {
      //   //   res = '明天'
      //   // } else if (week === 0) {
      //   if (week === 0) {
      //     res = $t('WeekSun_1') // '周日'
      //   } else if (week === 1) {
      //     res = $t('WeekMon_1') // '周一'
      //   } else if (week === 2) {
      //     res = $t('WeekTues_1') // '周二'
      //   } else if (week === 3) {
      //     res = $t('WeekWed_1') // '周三'
      //   } else if (week === 4) {
      //     res = $t('WeekThur_1') // '周四'
      //   } else if (week === 5) {
      //     res = $t('WeekFri_1') // '周五'
      //   } else if (week === 6) {
      //     res = $t('WeekSat_1') // '周六'
      //   }
      // } else if (language === 'EN') {
      //   if (week === 0) {
      //     res = 'Sun'
      //   } else if (week === 1) {
      //     res = 'Mon'
      //   } else if (week === 2) {
      //     res = 'Tue'
      //   } else if (week === 3) {
      //     res = 'Wed'
      //   } else if (week === 4) {
      //     res = 'Thu'
      //   } else if (week === 5) {
      //     res = 'Fri'
      //   } else if (week === 6) {
      //     res = 'Sat'
      //   }
      // }
      if (week === 0) {
        res = $t('WeekSun_1') // '周日'
      } else if (week === 1) {
        res = $t('WeekMon_1') // '周一'
      } else if (week === 2) {
        res = $t('WeekTues_1') // '周二'
      } else if (week === 3) {
        res = $t('WeekWed_1') // '周三'
      } else if (week === 4) {
        res = $t('WeekThur_1') // '周四'
      } else if (week === 5) {
        res = $t('WeekFri_1') // '周五'
      } else if (week === 6) {
        res = $t('WeekSat_1') // '周六'
      }
      return res
    }
    Vue.prototype.getNewDate = function (date, offsetY, offsetM, offsetD) {
      // date必须为yyyy-MM-dd的格式
      if (date) {
        let year = Vue.prototype.NewDate(date).getFullYear() + offsetY
        let month = Vue.prototype.NewDate(date).getMonth() + offsetM
        let day = Vue.prototype.NewDate(date).getDate() + offsetD
        return Vue.prototype.beTime(new Date().setFullYear(year, month, day))
      }
      return null
    }
    /**
     * 两个日期字符串相隔几天
     * @param {*开始日期字符串} arg1
     * @param {*结束日期字符串} arg2
     */
    Vue.prototype.getApartDayCount = function (arg1, arg2) {
      let num1 = Math.round(Vue.prototype.beTimeDateNumber(arg1) / 1000).toString()
      let num2 = Math.round(Vue.prototype.beTimeDateNumber(arg2) / 1000).toString()
      // let num1 = Math.round(new Date(new Date(arg1)).getTime() / 1000).toString()
      // let num2 = Math.round(new Date(new Date(arg2)).getTime() / 1000).toString()
      let apart = Math.abs(num1 - num2)
      return apart / 60 / 60 / 24
    }
    /**
     * 比较精确的两数相乘
     * @param {*乘数} arg1
     * @param {*被乘数} arg2
     */
    Vue.prototype.accMul = function (arg1, arg2) {
      var m = 0
      var s1 = arg1.toString()
      var s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) {
        // console.log(e)
      }
      try {
        m += s2.split('.')[1].length
      } catch (e) {
        // console.log(e)
      }
      return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
    }
    /**
     * 比较精确的两数相除
     * @param {*被除数} arg1
     * @param {*除数} arg2
     */
    Vue.prototype.accDiv = function (arg1, arg2) {
      var t1 = 0
      var t2 = 0
      var r1
      var r2
      try {
        t1 = arg1.toString().split('.')[1].length
      } catch (e) {
      }
      try {
        t2 = arg2.toString().split('.')[1].length
      } catch (e) {
      }
      r1 = Number(arg1.toString().replace('.', ''))
      r2 = Number(arg2.toString().replace('.', ''))
      return (r1 / r2) * (10 ** t2 - t1)
    }
    Vue.prototype.noValue = function (value) {
      return value === '{}' || value === '[]' || value === '' || value === undefined || value === null || value === 'undefined' || value === 'null'
    }
    /**
     * 判断手机号格式
     */
    Vue.prototype.isPhoneNumber = function (value, MobileCountryCode) {
      //  && value.length === 11
      if (typeof value === 'string') {
        let reg = /^1[0-9]{10}$/
        if (MobileCountryCode && MobileCountryCode !== '+86') {
          reg = /^[0-9]*$/
        }
        // 国内国外的手机号都可以，这里就只验证输入的是数字就行
        // let reg = /^[0-9]*$/
        return reg.test(value)
      }
      return false
    }
    /**
     * 判断邮箱格式
     */
    Vue.prototype.isEmail = function (value) {
      if (typeof value === 'string') {
        var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        return reg.test(value)
      }
      return false
    }
    /**
     * js取地址栏地址参数
     */
    Vue.prototype.getUrlKey = function (name) {
      return decodeURIComponent((new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(location.href) || [undefined, ''])[1].replace(/\+/g, '%20')) || null
    }
    /**
     * 判断身份证格式
     */
    Vue.prototype.idCard = function (value) {
      let res = {valid: true}
      if (typeof value === 'undefined' || !value) {
        res.valid = false
        res.msg = '请输入身份证号码'
        return res
      }
      value = value.replace(/(^\s*)|(\s*$)/g, '')// 过滤空格及回车
      let code = value
      // if (code.length !== 18 && code.length !== 15) {
      if (code.length !== 18) {
        res.valid = false
        res.msg = '身份证号码长度有误'
        return res
      }
      value = value.toUpperCase()
      const DistrictCodeArray = ['11', '12', '13', '14', '15', '21', '22', '23', '31', '32', '33', '34', '35', '36', '37', '41', '42', '43', '44', '45', '46', '50', '51', '52', '53', '54', '61', '62', '63', '64', '65', '71', '81', '82', '91']
      if (!DistrictCodeArray.includes(code.substr(0, 2).toString())) {
        res.valid = false
        res.msg = '此身份证区域码校验无效'
        return res
      }

      if (code.length === 18) {
        let year, month, day, birthday;
        year = code.substr(6, 4);
        month = code.substr(10, 2);
        day = code.substr(12, 2);
        birthday = new Date(year, month - 1, day);
        if (birthday.getFullYear() != year ||
          birthday.getMonth() + 1 != month ||
          birthday.getDate() != day
        ) {
          res.valid = false
          res.msg = '此身份证件号码格式有误'
          return res
        }

        code = code.split('')
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        // 校验位
        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
        var sum = 0
        var ai = 0
        var wi = 0
        for (var i = 0; i < 17; i++) {
          ai = code[i]
          wi = factor[i]
          sum += ai * wi
        }
        if ((parity[sum % 11] + '').toUpperCase() !== code[17].toUpperCase() + '') {
          res.valid = false
          res.msg = '此身份证件号码格式有误'
          return res
        }
      }
      return res
    }
    /**
     * 数组中是否有相同项
     */
    Vue.prototype.isRepeat = function (arr) {
      var hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) return true
        hash[arr[i]] = !Vue.prototype.noValue(arr[i])
      }
      return false
    }
    /**
     * 身份证号码获取出生日期
     */
    Vue.prototype.calBirthDate = function (certificateNo) {
      let code = certificateNo
      let birth = (code.length === 18 ? code.slice(6, 14) : code.slice(6, 12))
      let birthDate = new Date()
      if (code.length === 18) {
        birthDate.setFullYear(birth.slice(0, 4), birth.slice(4, 6) - 1, birth.slice(6, 8))
      } else {
        birthDate.setFullYear('19' + birth.slice(0, 2), birth.slice(2, 4) - 1, birth.slice(4, 6))
      }
      return birthDate
    }
    /**
     * 身份证号码获取性别
     * @returns 1: 男; 2: 女
     */
    Vue.prototype.calGender = function (certificateNo) {
      let code = certificateNo
      let gender = Boolean(!((code.length === 18 ? code.slice(16, 17) : code.slice(14, 15)) % 2 === 0))
      return gender ? 1 : 2
    }
    /**
     * 计算年龄,精确到小时,天,岁
     * @param {*例子1981-02-28} beginStr
     */
    Vue.prototype.getAge = function (beginStr) {
      var birthDate = new Date(beginStr)
      var now = new Date()
      var age = now.getFullYear() - birthDate.getFullYear()
      var ageNowYear = new Date()
      ageNowYear.setFullYear(now.getFullYear())
      ageNowYear.setMonth(now.getMonth())
      ageNowYear.setDate(now.getDate() + 1)
      var birthNowYear = new Date()
      birthNowYear.setFullYear(now.getFullYear())
      birthNowYear.setMonth(birthDate.getMonth())
      birthNowYear.setDate(birthDate.getDate())
      age = (now.getFullYear() - birthDate.getFullYear()) + ((ageNowYear.valueOf() - birthNowYear.valueOf()) / (365 * 24 * 60 * 60 * 1000))
      age = age.toFixed(3)
      return age
    }
    /**
     * 对对象或者字符串做trim处理, 只遍历了两层
     */
    Vue.prototype.handleOfTrim = function (val) {
      if (typeof val === 'string') {
        val = val.trim()
      } else if (typeof val === 'object') {
        Object.keys({...val}).forEach(key => {
          if (typeof val[key] === 'string') {
            val[key] = val[key].trim()
          }
        })
      }
      return val
    }

    /**
     * 静态文件引用地址替换
     */
    Vue.prototype.domainNameReplace = function (str) {
      let proOrgin = 'https://h5comm.homsom.com'
      let testUrl = 'https://h5commuattest.homsom.com'
      let fatOrgin = 'https://h5commtest.homsom.com'
      let origin = window.location.origin
      if (origin === 'https://waptest.homsom.com') {
        str = str.replace(proOrgin, fatOrgin)
      } else if (origin === 'http://localhost:9001') {
        // str = str.replace(proOrgin, 'http://localhost:8089')
        str = str.replace(proOrgin, fatOrgin)
      } else if (origin === 'https://wapuattest.homsom.com') {
        str = str.replace(proOrgin, testUrl)
      }
      return str
    }

    /***
     * 版本号
     * */
    Vue.prototype.VERSION = VERSION

    /** 根据language获取城市 名称 */
    Vue.prototype.getCityName = (Obj) => {
      let language = Vue.prototype.language
      if (!Obj || Vue.prototype.noValue(Obj)) {
        return ''
      }

      if (language === 'TC' || language === 'CN' || language === 'HK') {
        return Obj.CityName_CN
      }

      if (language === 'JAP') {
        return Obj.CityName_JP || Obj.CityName_CN
      }
      return Obj.CityName_EN
    }
    /** 根据language获取城市 long名称 */
    Vue.prototype.getCityLongName = (Obj) => {
      let language = Vue.prototype.language
      if (!Obj || Vue.prototype.noValue(Obj)) {
        return ''
      }

      if (language === 'TC' || language === 'CN' || language === 'HK') {
        return Obj.LongName_CN
      }

      if (language === 'JAP') {
        return Obj.LongName_JP || Obj.LongName_CN
      }
      return Obj.LongName_EN
    }

    /** 根据language获取机场 名称 */
    Vue.prototype.getAirPortName = (Obj) => {
      let language = Vue.prototype.language
      if (!Obj || Vue.prototype.noValue(Obj)) {
        return ''
      }
      if (language === 'TC' || language === 'CN' || language === 'HK') {
        return Obj.AirPortName_CN
      }
      if (language === 'JAP') {
        return Obj.AirPortName_JP || Obj.AirPortName_CN
      }
      return Obj.AirPortName_EN
    }

    /** 根据language获取CountryName 名称 */
    Vue.prototype.getCountryName = (Obj) => {
      let language = Vue.prototype.language
      if (!Obj || Vue.prototype.noValue(Obj)) {
        return ''
      }
      if (language === 'TC' || language === 'CN' || language === 'HK') {
        return Obj.CountryName_CN
      }

      if (language === 'JAP') {
        return Obj.CountryName_JP || Obj.CountryName_CN
      }
      return Obj.CountryName_EN
    }

    /** 根据language获取手机国家代码 CountryName 名称 */
    Vue.prototype.getMobileCountryName = (Obj) => {
      let language = Vue.prototype.language
      if (!Obj || Vue.prototype.noValue(Obj)) {
        return ''
      }
      if (language === 'TC' || language === 'CN' || language === 'HK') {
        return Obj.CountryName_CN
      }

      if (language === 'JAP' && Obj.CountryName_JP) {
        return Obj.CountryName_JP
      }
      return Obj.CountryName_EN || Obj.CountryName_CN
    }

    /** 根据language获取国家 名称 */
    Vue.prototype.getNationName = (Obj) => {
      let language = Vue.prototype.language
      if (!Obj || Vue.prototype.noValue(Obj)) {
        return ''
      }
      if (language === 'TC' || language === 'CN' || language === 'HK') {
        return Obj.NationName_CN
      }
      if (language === 'JAP' && Obj.NationName_JP) {
        return Obj.NationName_JP
      }
      return Obj.NationName_EN || Obj.NationName_CN
    }

    /** 根据币种 获取币种单位 */
    Vue.prototype.CurrencyUnit = () => {
      return Vue.prototype.CurrencySymbol || '￥'
    }

    /** 根据币种 获取币种单位 */
    Vue.prototype.CurrencyInfo = null

    /** 金额千分位格式化 */
    Vue.prototype.$formatMoney = (num) => {
      let result = []
      let tempArr = []
      let counter = 0;
      num = (num || 0);

      // 对于包含 + - 的特殊处理
      let unit = ''
      if (num.toString().includes('-')) {
        num = num.toString().replace('-', '')
        unit = '-'
      }

      if (num.toString().includes('+')) {
        num = num.toString().replace('+', '')
        unit = '+'
      }

      // 先用小数点分割，格式化整数部分
      tempArr = num.toString().split('.');
      // 整数部分
      num = tempArr[0];
      // 把整数部分分割成单个字符数组
      num = num.split('');

      for (let i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i !== 0) {
          result.unshift(',');
        }
      }
      // temp_arr数组长度为2表示有小数
      if (tempArr.length === 2) {
        result.push('.' + tempArr[1]);
      }
      result = result.join('');
      return `${unit}${result}`;
    }

    /** 姓名展示处理 */
    Vue.prototype.$getPassengerName = (item) => {
      if (!item) {
        return ''
      }
      let ChName = item.ChName
      let EnName = ''
      if (item.LastName && item.FirstName) {
        EnName = `${item.LastName}/${item.FirstName}`
      } else {
        EnName = `${item.LastName || ''}${item.FirstName || ''}`
      }
      if (item.DefaultNameType === 0) {
        return (ChName || EnName || item.Name) || item.NickName || '--'
      } else if (!item.DefaultNameType) {
        return (item.Name || ChName || EnName) || item.NickName || '--'
      } else {
        return (EnName || ChName || item.Name) || item.NickName || '--'
      }
    },

      /** \n \\n 替换成 <br> */
    Vue.prototype.$getValReplaceWrap = (val) => {
      if (val && typeof val == 'string') {
        val = val.replace(/\\n/g, '<br>')
        return val.replace(/\n/g, '<br>')
      }
      return val
    }

    Vue.prototype.$getPlaceholder = (type, Required) => {
      let $t = Vue.prototype.$t
      if (type === 1) {
        return Required ? $t('RequiredChoosePleaseChoose') : $t('PleaseChoose')
      } else if (type === 2) {
        return Required ? $t('RequiredFillPleaseFillOrChoose') : $t('NotNecessaryFillPleaseFillOrChoose')
      } else {
        return Required ? $t('RequiredFillPleaseFill') : $t('PleaseFill')
      }
    }

    Vue.prototype.getIsApp = getIsApp

    Vue.prototype.homsomClose = () => {
      let isApp = getIsApp()
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
      let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
      if (isAndroid && isApp) {
        window.homsom && window.homsom.close()
      } else if (isIos && isApp) {
        window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.close && window.webkit.messageHandlers.close.postMessage(1)
      }
    }
  }
}
